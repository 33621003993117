import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import image1 from 'images/project-13a.png'
import image2 from 'images/project-13b.png'
import image3 from 'images/project-13c.png'
import image4 from 'images/project-13d.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Affordable family rentals"
      meta={[
        {
          name: 'description',
          content: '',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Affordable family rentals"
      intro="In 2020, the Ryhaven Trust assisted a family to relocate and relevel their house on a large section in Naenae."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            This enabled the owners to subdivide the property and sell the newly-created surplus section to the Trust.
          </Paragraph>
          <Paragraph>
            We have since received consent to build two semi-detached homes on this site, with the intention of offering them as affordable transition housing. The move marks an additional direction for the Trust, which generally focuses on dealing with deferred maintenance issues for homeowners.
          </Paragraph>
          <Paragraph>
            The construction work of the new homes is ticking along with the team hard at work, laying carpet, painting and landscaping the section.
          </Paragraph>
          <Paragraph>
            A number of our sponsors are assisting with the project including Carters, NME Electrical, Metalcraft Roofing, and Dulux.
          </Paragraph>
          <Paragraph>
            The end is in sight with the build to be complete by April 2021.
          </Paragraph>
          <img className={styles.image} src={image4} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />

        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
